import React from "react"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"

const Jobapply = () => {
	return (
		<>
			<HeaderTwo />

			<Footer />
		</>
	)
}

export default Jobapply
